import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies';

import ApiModule from './modules/api.js';
import DialogModule from './modules/dialogs.js';
import HideNavModule from './modules/hideNav.js'
import ConfirmModalModule from './modules/confirmModal'

Vue.use(Vuex)
Vue.use(VueCookies)

const TOKENCOOKIEKEY = 'jh_token';

export default new Vuex.Store({
  modules: {
    api: ApiModule,
    dialogs: DialogModule,
    hideNav: HideNavModule,
    confirmModal: ConfirmModalModule,
  },
  state: {
    debug: true,
    storeBusy: [],
    errMsgs: [],
    msgModalContent: '',

    user: null,

    token: Vue.$cookies.get(TOKENCOOKIEKEY) || '',
    // token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJ6eGF1c3RpbkBnbWFpbC5jb20iLCJpYXQiOjE2ODMwMTI0NDZ9.PUBlljdGC_ZIR2G_KstnnYqqGq3VoA-lxtgi8JSdgpI',
    TOKENCOOKIEKEY: TOKENCOOKIEKEY,
    lineClientId: '1655055663',

    redirectRoute: Vue.$cookies.get('redirectRoute') || null,
    passedData: null,
  },
  getters: {
    hasToken(state) {
      return !!state.token;
    },
    userToken(state) {
      return state.token;
    },
    isStoreBusy(state) {
      return state.storeBusy.length > 0;
    },
    userData(state) {
      return state.user;
    },
    tokenCookieKey(state) {
      return state.TOKENCOOKIEKEY;
    },
    passData(state) {
      return state.passedData;
    },
  },
  mutations: {
    APPENDBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index === -1) {
        state.storeBusy.push(payload);
      }
    },
    CLEARBUSY(state, payload) {
      const index = state.storeBusy.indexOf(payload);
      if (index !== -1) {
        state.storeBusy.splice(index, 1);
      }
    },
    APPENDERRORMSG(state, errMsg) {
      state.errMsgs.push(errMsg);
    },
    CLEARERRORMSG(state) {
      state.errMsgs.splice(0, state.errMsgs.length);
    },

    UPDATETOKEN(state, token) {
      state.token = token;
      Vue.$cookies.set(state.TOKENCOOKIEKEY, token, "30d");
    },
    CLEARTOKEN(state) {
      state.token = '';
      Vue.$cookies.remove(state.TOKENCOOKIEKEY);
    },
    UPDATEUSER(state, user) {
      if (user === null) {
        state.user = null;
      } else {
        state.user = Object.assign({}, state.user, user);
      }
    },
    setRedirectRoute(state, route) {
      state.redirectRoute = route;
      // console.log(route);
      Vue.$cookies.set('redirectRoute', route);
    },
    clearRedirectRoute(state) {
      state.redirectRoute = null;
      Vue.$cookies.remove('redirectRoute');
    },
    setPassedData(state, data) {
      state.passedData = data;
    },
    clearPassedData(state) {
      state.passedData = null;
    },
  },
  actions: {
    appendComponentBusy(context, busyName) {
      context.commit("APPENDBUSY", busyName);
    },
    clearComponentBusy(context, busyName) {
      context.commit("CLEARBUSY", busyName);
    },
    appendErrorMsg(context, errMsg) {
      context.commit("APPENDERRORMSG", errMsg);
    },
    async refreshUser(context) {
      try {
        const user = await context.dispatch('api/getUserMePromise');
        context.commit("UPDATEUSER", user);
      } catch (e) {
        console.error(e);
      }
    },
    // async initLoginToken(context) {
    //   console.log('context:' + context)
    //   console.log('context:' + Vue.$cookies.get('jh_token'))
    //   const savedToken = Vue.$cookies.get(context.state.TOKENCOOKIEKEY);
    //   console.log(savedToken);
    //   if (savedToken !== null) {
    //     try {
    //       const newToken = await context.dispatch('api/postRefreshToken', savedToken);
    //       await context.dispatch('login', newToken.token);
    //     } catch(e) {
    //       console.error(e);
    //       context.dispatch('logout');
    //     }
    //   }
    // },
    async refreshToken({ getters, dispatch, commit }, token) {
      // 使用現有token向server索取新token
      if(getters.isAuthenticated) {
        try {
          const response = await dispatch('api/postRefreshToken', token);
          commit('UPDATETOKEN', response.token);
        } catch (error) {
          console.error('Refresh token error:', error);
          dispatch('logout');
        }
      }
    },
    // async login(context, token) {
    //   context.commit('UPDATETOKEN', token);
    //
    //   const busyName = 'LOGIN';
    //   context.commit("APPENDBUSY", busyName);
    //   context.commit('UPDATETOKEN', token);
    //   try {
    //     await context.dispatch('refreshUser');
    //   } catch (e) {
    //     // 可能token有誤
    //     // context.dispatch('showMsgModal', '登入可能有誤，請重新登入');
    //     context.dispatch('logout');
    //   } finally {
    //     context.commit("CLEARBUSY", busyName);
    //   }
    // },
    async login({ dispatch, commit }, token) {
      const busyName = 'LOGIN';
      commit("APPENDBUSY", busyName);
      commit('UPDATETOKEN', token);
      try {
        await dispatch('refreshUser');
      } catch (error) {
        console.error('Login error:', error);
        dispatch('logout');
      } finally {
        commit("CLEARBUSY", busyName);
      }
    },
    logout(context) {
      context.commit('CLEARTOKEN');
      context.commit("UPDATEUSER", null);
    },
    async ensureUser({ state, dispatch }) {
      if (!state.user && !!state.token) {
        // 如果有 token 但沒有使用者資料，先進行 refresh token，再進行 login 請求
        await dispatch('refreshToken', state.token);
        await dispatch('login', state.token);
      }
    },
    getRedirectRoute() {
      const redirect = Vue.$cookies.get('redirectRoute') || null;
      return redirect;
    },
  }
})
