<template>
  <div class="layout" :class="{'hide-nav': hideNav, 'show-footer': showFooter}">
    <Navbar v-if="!hideNav"></Navbar>
    <router-view />
    <Footer v-if="showFooter"></Footer>

    <MsgModal />

    <transition name="fade">
      <BusyLoading v-if="busy"></BusyLoading>
    </transition>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Navbar from '@/views/navbar/Navbar.vue';
import Footer from '@/views/footer/Footer.vue';
import MsgModal from '@/views/common/MsgModal.vue';
import BusyLoading from '@/views/common/BusyLoading';

export default {
  name: 'MainApp',
  data() {
    return {
    };
  },
  props: {
  },
  components: {
    Navbar,
    Footer,
    MsgModal,
    BusyLoading,
  },
  computed: {
    ...mapState(['debug', 'errMsgs', 'storeBusy']),
    ...mapGetters(['isStoreBusy']),
    busy() {
      return this.isStoreBusy;
    },
    hideNav() {
      // const hideNav = Boolean(this.$route.meta.hideNav);
      // return !hideNav
      return this.$store.state.hideNav.hideNav;
    },
    showFooter() {
      return this.$route.name === 'Index';
    },
	},
  watch: {
    errMsgs(errMsgs) {
      if (errMsgs.length > 0) {
        this.receiveErrorMsgs(errMsgs);
      }
    },
  },
  mounted() {
	},
  methods: {
    receiveErrorMsgs(errMsgs) {
      for (const errMsg of errMsgs) {
        if (this.debug) {
          console.error(errMsg);
        }
        this.$store.dispatch('dialogs/showMsgModal', errMsg);
      }
      this.$store.commit('CLEARERRORMSG');
    },
  }
}
</script>

<style lang="scss">
  @import "assets/scss/main";
  @import "assets/scss/joyhike-main";
</style>
