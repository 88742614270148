<template>
  <footer class="layout-footer">
    <div class="container d-flex">
      <div class="guideline-group">
        <div class="title fs-small-11">關於</div>
        <a class="link fs-body-14" href="https://blog.joyhike.com/" id="footer-blogger" target="_blank">部落格</a>
        <router-link class="link fs-body-14" :to="{name: 'DatabaseRange'}" id="footer-database">資料庫範圍</router-link>
        <!-- <a class="link fs-body-14" href="#">揪山的使用方式</a> -->
        <router-link class="link fs-body-14" :to="{name: 'Term'}" id="footer-term">使用條款 / 隱私權聲明</router-link>
        <div class="fs-body-14">揪山股份有限公司  90839274</div>
      </div>
      <div class="guideline-group">
        <div class="title fs-small-11">聯繫我們</div>
        <span class="link fs-body-14">hello@joyhike.com</span>
        <div class="d-flex">
          <a class="link fs-body-14 mr-2" href="https://lin.ee/0kSLswL" target="_blank" id="footer-official">
            <Icon class="contact-icon" name="line_green" size="24" />
          </a>
          <a class="link fs-body-14 mr-2" href="https://www.facebook.com/Joyhike-105485528667840/" target="_blank" id="footer-official">
            <Icon class="contact-icon" name="Facebook" size="24" />
          </a>
          <a class="link fs-body-14" href="https://instagram.com/joyhike?utm_medium=copy_link" target="_blank" id="footer-official">
            <Icon class="contact-icon" name="Instagram" size="24" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>


<script>

export default {
  name: 'Index',
  data() {
    return {
    };
  },
  props: {
  },
  components: {
  },
  computed: {
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .guideline-group .title.fs-small-11{
    transform-origin: bottom left;
  }
</style>
