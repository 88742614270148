
export default {
  namespaced: true,
  state: {
    hideNav: false,
  },
  getters: {
  },
  mutations: {
    SET_HIDE_NAV(state, value) {
      state.hideNav = value;
    },
  },
  actions: {
  },
}
