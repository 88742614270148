import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Cookies from 'vue-cookies';
import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
// 不在前端顯示meta屬性
Vue.config.productionTip = false;
// 關掉可防止其他開發者利用devtool中的vue工具窺視vue的資料結構, 開啟則方便開發
Vue.config.devtools = true;

Vue.use(Cookies);

const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("./assets/images/icon/svg", true, /\.svg$/);
requireAll(req);
import Icon from "@/components/common/Icon";
Vue.component("Icon", Icon);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

