
export default {
  namespaced: true,
  state: {
    msgModalContent: '',
  },
  getters: {
    isMsgModalShow(state) {
      return state.msgModalContent.length > 0;
    },
  },
  mutations: {
    SHOWMSGMODAL(state, payload) {
      state.msgModalContent = payload.toString();
    },
    CLEANMSGMODAL(state) {
      state.msgModalContent = '';
    },
  },
  actions: {
    showMsgModal(context, msg) {
      context.commit("SHOWMSGMODAL", msg);
    },
    clearMsg(context) {
      context.commit('CLEANMSGMODAL');
    },
  },

}
