<template>
  <div id="app">
<!--    <MainApp v-if="appInited"></MainApp>-->
    <MainApp></MainApp>
  </div>
</template>

<script>
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

import moment from 'moment';
moment.locale('zh-tw');

Vue.use(BootstrapVue);
// Vue.use(require('vue-cookies'));

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import MainApp from './MainApp.vue';

export default {
  name: 'App',
  metaInfo() {
    const title = 'Joyhike 揪山';
    const desc = '直覺的路線查詢，適合分享的行程資訊，即時更新的氣象預測，揪山協助您的登山計畫，讓登山的事前準備更順暢！';
    return {
      title: `${title}`,
      meta: [
        { itemprop: 'name', content: title },
        { name: 'description', content: desc },
        { property: 'og:title', content: title },
        { property: 'og:description', content: desc },
        { property: 'og:site_name', content: title },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: desc },
        { name: 'twitter:card', content: 'summary' },
      ],
    }
  },
  data() {
    return {
      // appInited: false,
    }
  },
  components: {
    MainApp,
  },
  // async mounted() {
  //   try {
  //     await this.$store.dispatch('initLoginToken');
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     this.appInited = true;
  //   }
  // },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="scss">
  #app {
    // background-color: #ddd;
  }
</style>
