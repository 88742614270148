
export default {
  namespaced: true,
  state: {
    showConfirmModal: false,
    confirmMessage: '',
    confirmBtnText: '確定',
    confirmCallback: null,
  },
  getters: {
  },
  mutations: {
    OPEN_CONFIRM_MODAL(state, { message, btnText, callback }) {
      state.showConfirmModal = true;
      state.confirmMessage = message;
      state.confirmBtnText = btnText;
      state.confirmCallback = callback;
    },
    CLOSE_CONFIRM_MODAL(state) {
      state.showConfirmModal = false;
      state.confirmMessage = '';
      state.confirmBtnText = '確定';
      state.confirmCallback = null;
    }
  },
  actions: {
    openConfirmModal({ commit }, payload) {
      commit('OPEN_CONFIRM_MODAL', payload);
    },
    closeConfirmModal({ commit }) {
      commit('CLOSE_CONFIRM_MODAL');
    },
    confirmModal({ state, commit }) {
      if (state.confirmCallback) {
        state.confirmCallback();
      }
      commit('CLOSE_CONFIRM_MODAL');
    }
  },
}
